<template lang="pug">
  drr.trim-box(
    ref="trimBox"
    key="drr-trim-box"
    :class="{'is-dark': isDarkMode}"
    :x="x"
    :y="y"
    :w="width"
    :h="height"
    :angle="0"
    :selected="true"
    :maxSize="maxSize"
    :minSize="minSize"
    :outerBound="outerBound"
    :draggable="true"
    :isCropper="true"
    :resizable="true"
    :rotatable="false"
    :selectable="true"
    :onlyWidthResize="true"
    @resize="resizing"
    @resizestop="resized"
    @drag="dragging"
    @dragstop="dragged"
  )
    div.start-time-sign
      h4.text {{humanizeStartTime}}
    div.end-time-sign
      h4.text {{humanizeEndTime}}
</template>

<script>
import { VueDragResize } from 'vue-drag-resize';
import drr from '../../../../plugins/@minogin/vue-drag-resize-rotate/src/drr.vue';
import { secToDigitalStr } from '../../../../assets/scripts/utilities';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'TrimBox',
  mixins: [darkModeMixin],
  components: {
    VueDragResize,
    drr,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    left: {
      type: Number,
      required: true,
    },
    minWidth: {
      type: Number,
      required: true,
    },
    maxSize: {
      type: Object,
      required: true,
    },
    minSize: {
      type: Object,
      required: true,
    },
    outerBound: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      humanizeStartTime: '00:00:00',
      humanizeEndTime: '00:00:00',
      startTime: 0,
      endTime: 0,
    };
  },
  methods: {
    dragging(rect) {
      // this.safePosition();
      this.updating(rect);
    },
    dragged(rect) {
      // this.safePosition();
      this.updated(rect);
    },
    parsePositionToTime(position) {
      return position / this.minWidth / 10;
    },
    parseTimeToPosition(time) {
      return time * 10 * this.minWidth;
    },
    resizing(rect) {
      this.safePosition();
      this.updating(rect);
    },
    resized(rect) {
      this.safePosition();
      this.updated(rect);
    },
    safePosition() {
      const elTrimBox = this.$refs.trimBox.$el;
      if (elTrimBox.offsetTop !== 0) {
        elTrimBox.style.top = '0px';
      }
    },
    updating({ x, w }) {
      const halfW = w / 2;
      this.$emit('updating', {
        width: w,
        left: x - halfW,
      });
      // console.debug('trimBox:updating', {
      //   x, w,
      // });
    },
    updated({ x, w }) {
      const halfW = w / 2;
      this.$emit('updated', {
        width: w,
        left: x - halfW,
      });
    },
    updateX() {
      this.x = this.width / 2 + this.left;
    },
  },
  mounted() {
    this.y = this.height / 2;
    this.$nextTick(() => {
      this.safePosition();
    });
    // console.debug('TrimBox:mounted');
  },
  watch: {
    left: {
      handler(left) {
        this.updateX();
        this.startTime = this.parsePositionToTime(left).toFixed(1);
        // If left value was changed by dragging the trim box, we also need to update endTime.
        this.endTime = this.parsePositionToTime(left + this.width).toFixed(1);
      },
      immediate: true,
    },
    width: {
      handler(width) {
        this.updateX();
        this.endTime = this.parsePositionToTime(this.left + width).toFixed(1);
      },
      immediate: true,
    },
    startTime: {
      handler(startTime) {
        this.humanizeStartTime = secToDigitalStr(startTime, { showMilliSeconds: true, defaultFormat: false });
      },
      immediate: true,
    },
    endTime: {
      handler(endTime) {
        this.humanizeEndTime = secToDigitalStr(endTime, { showMilliSeconds: true, defaultFormat: false });
      },
      immediate: true,
    },
    minWidth: {
      handler() {
        this.updateX();
        this.endTime = this.parsePositionToTime(this.left + this.width).toFixed(1);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.trim {
  &-box {
    z-index: 2;
    cursor: auto !important;

    &.inactive {
      border: 2px solid #80aeff;
      .drr-stick {
        border-color: #80aeff !important;
      }
    }

    &.active {
      border: 0;
      background: rgba($sectionBlue, 0.5);
      top: 0 !important;
      border-radius: 8px;

      &.is-dark {
        background: rgba($lightGrey400, 0.15);
      }
    }

    &::before {
      outline: none !important;
    }

    .drr-stick {
      position: absolute;
      top: 0;

      &-ml,
      &-mr {
        width: 25px !important;
        height: 100% !important;
        background-color: $light !important;
        border: 11px solid var(--blue-darkgrey5) !important;
        margin-top: 0 !important;
        cursor: ew-resize;
        display: block !important;
        border-radius: 8px;
      }

      &-ml {
        left: -6px !important;
      }
      &-mr {
        right: -6px !important;
      }
    }

    .start-time-sign,
    .end-time-sign {
      position: absolute;
      top: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 28px;
      background: var(--blue-darkgrey5);
      border-radius: 20px;
      z-index: 8;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;

      .text {
        font-size: 0.6875rem;
        color: $light;
        margin: 0;
        font-weight: 500;
      }
    }

    .start-time-sign {
      left: -23px;
    }

    .end-time-sign {
      right: -23px;
    }
  }
}
</style>
