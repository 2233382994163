<template lang="pug">
  CanvasModal.video-trimmer__modal(
    :is-show="true"
    :is-grey="true"
    @closeOverlay="closeModal"
  )
    .video-trimmer__content-wrapper
      //- --------
      //- Header
      //- --------
      .video-trimmer__header
        .header__title
          i.icon-upload2
          h4.title Upload Video
        BaseButton.btn-apply(
          v-if="!isVideoUploaded(trimmingVideo)"
          :class="{'is-disabled': isUploadVideoButtonDisabled, 'is-dark': isDarkMode}"
          is-primary
          :is-canvas="true"
          @click="upload"
        ) Done

      //- --------
      //- Content
      //- --------
      .video-trimmer__container
        .video-trimmer__list
          h4.list-title Files
          .video-trimmer__list-item(
            v-for="(video, index) in videos"
            :key="`before-upload-video-${index}`"
            ref="listItems"
          )
            .video-trimmer__video-wrapper(
              :class="videoTrimmerWrapperClass(index)"
              @click="trimVideo(index)"
            )
              .video-trimmer__video-overlay(
                :class="[ isVideoUploading(video) ? 'is-uploading' : isVideoUploaded(video) ? 'is-uploaded' : '' ]"
              )
                i.icon.icon-tick
                BaseButton.btn--delete(
                  :isTall="true"
                  :isIcon="true"
                  title="Remove video"
                  @click.stop="removeVideo(index)"
                )
                  i.icon.icon-trash-solid2

                span.label-uploading Uploading
                .running-dots
                  div
                  div
                  div
                  div

                label.video-overlay__video-length-description
                div.failed-video-thumbnail
                  i.icon-video.thumbnail-icon

              video.video-trimmer__video(
                :key="`${video.name}-${index}-${video.lastModified}`"
                ref="unTrimmedVideos"
              )
                source(
                  :src="makeFileUrl(video)"
                  type="video/mp4"
                )

          .upload-another-button__wrapper
            input(
              type="file"
              ref="fileUpload"
              :accept="joinedAcceptableFileExtensions"
              @change="$parent.doSelectFile($event.target)"
            )
            button.upload-another-button(
              :class="{'is-dark': isDarkMode}"
              @click="showFileBrowser"
            )
              i.icon-plus

        .video-trimmer__preview
          template(v-if="isVideoFailedLoading")
            div.failed-video-loading__container
              i.icon-files.container-icon
              h4.container-title Whoops! We need to work on this file.
              p.container-description
                | This file format requires some converting before it can be used in OFFEO. <br/>
                | Click
                strong "Done"
                | to begin the conversion process.
              p.container-description.description--bottom Once its completed, you will see it in your files.

          template(v-else-if="trimmingVideo && isTrimmable")
            video#trimming-video(
              ref="trimmingVideo"
              :key="`video-tag-${trimmingVideoUniqueId}`"
            )
              source(
                :src="trimmingVideo | parseVideoURL"
                type="video/mp4"
              )
            .video-trimmer__controls(
              :class="{'controls--vertical-align-center': isVideoUploaded(trimmingVideo) || isVideoUploading(trimmingVideo)}"
            )
              template(v-if="trimmingVideo")
                template(v-if="isVideoUploaded(trimmingVideo) || isVideoUploading(trimmingVideo)")
                  h3.video-uploaded-text
                    | Trimmed video has been uploaded
                template(v-else)
                  button.video-trimmer__controls__button.btn.btn--play(
                    v-if="!isLoading"
                    type="button"
                    @click="playVideo"
                    ref="btnPlay"
                  )
                    i(
                      class="icon"
                      :class="[isPlaying ? 'icon-pause' : 'icon-play']"
                    )
                    span.white-background-overlay(v-if="isDarkMode")

                  .video-trimmer__controls__slider(
                    ref="slider"
                  )
                    .slider-bar( ref="sliderBar" )
                    .slider__active-bar(
                      :style="activeBarStyle"
                    )
                    template(
                      v-if="shouldShowTrimBox"
                    )
                      TrimBox(
                        :key="`trim-box-${trimBoxKey}`"
                        :id="trimBoxKey"
                        :width="trimBoxObj.width"
                        :height="sliderHeight"
                        :left="trimBoxObj.startTime"
                        :minWidth="sliderBarWidthForAnInterval"
                        :maxSize="trimBoxMaxSize"
                        :minSize="trimBoxMinSize"
                        :outerBound="trimBoxOuterBound"
                        @updating="trimBoxUpdating"
                        @updated="trimBoxUpdated"
                      )
                    VueDragResize.slider-bar__indicator(
                      :isActive="true"
                      :w="2"
                      :h="44"
                      :x="sliderBarXPosition"
                      :y="0"
                      :parentLimitation="true"
                      axis="x"
                      :isResizable="false"
                      @dragging="draggingVideoCurrentTimeIndicator"
                    )
          template(v-else)
            div.failed-video-loading__container.container--try-again
              i.icon-files.container-icon
              h4.container-title Oops! The upload failed.
              p.container-description Please try again.

              BaseButton.container-button(
                :is-canvas="true"
                :is-primary="true"
                :is-tall="true"
                @click="showFileBrowser"
              ) Upload

      GlobalEvents(
        @keydown.prevent.space.exact="playVideo"
      )
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import { isEqualFile, secToDigitalStr } from '@/assets/scripts/utilities';
import TrimBox from './children/TrimBox.vue';
import VideoElement from '../CanvasContainer/children/VideoElement.vue';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

export default {
  name: 'BeforeUploadOverlay',
  mixins: [darkModeMixin],
  props: {
    isUploading: {
      type: Boolean,
      required: true,
    },
    videos: {
      type: Array,
      default() {
        return [];
      },
    },
    uploadingVideos: {
      type: Array,
      default() {
        return [];
      },
    },
    uploadedVideos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    TrimBox,
    VideoElement,
    VueDragResize,
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      acceptableFileExtensions: ['mp4', 'mov'],

      containerWidth: 512,
      videoCurrentTimeIndicatorPosition: 0,
      elTrimmingVideo: null,
      fullVideoLength: 10,
      minTrimmableLength: 10,
      isLoading: false,
      isPlaying: false,
      isTrimmable: true,
      startTime: 0,
      trimmedRecords: {},

      sliderHeight: 44,
      sliderBarWidth: 0,
      sliderBarWidthForAnInterval: 0,

      trimBoxKey: null,
      trimBoxObj: {},
      trimBoxMinSize: {},
      trimBoxMaxSize: {},
      trimmingVideo: null,
      trimmingVideoIndex: null,
      trimBoxOuterBound: {
        x: 0,
        y: 24,
        w: 0,
        h: 44,
      },

      loadingVideoFailed: false,
      failedVideoLoadingIndex: [],
    };
  },
  computed: {
    sliderBarXPosition() {
      return this.videoCurrentTimeIndicatorPosition - 2; // minus 2 so doesn't overlap trimmer stick
    },
    duration() {
      return this.elTrimmingVideo ? this.elTrimmingVideo.duration : 0;
    },
    joinedAcceptableFileExtensions() {
      return `.${this.acceptableFileExtensions.join(', .')}`;
    },
    pauseOrPlay() {
      return this.isPlaying ? 'pause' : 'play';
    },
    shouldShowTrimBox() {
      return (
        this.trimBoxKey !== null && this.trimBoxObj.width && this.trimBoxObj.startTime !== undefined
      );
    },
    isMultipleVideos() {
      return this.videos.length > 1;
    },
    trimmingVideoUniqueId() {
      return `${this.trimmingVideoIndex}-${this.trimmingVideo.name}-${this.trimmingVideo.lastModified}`;
    },
    activeBarStyle() {
      const styleObj = {};
      const activeTrimmedSectionWidth = this.trimBoxObj.width;
      const startPoint = this.trimBoxObj.startTime;

      styleObj.width = `${activeTrimmedSectionWidth}px`;
      styleObj.left = `${startPoint}px`;

      return styleObj;
    },
    isUploadVideoButtonDisabled() {
      return !this.trimmingVideo || !this.isTrimmable || this.isVideoUploading(this.trimmingVideo);
    },
    isShowErrorVideoSign() {
      return this.isVideoFailedLoading || this.isUploadVideoButtonDisabled;
    },
    isVideoFailedLoading() {
      // this computed is different with 'loadingVideoFailed', this one is to check with the previous record whether
      // a video is failed to load before, because previously got a case where a video that is failed to load before,
      // can be opened again by user by click other video and click back, which the result was can see the video preview but cant trim.
      return this.failedVideoLoadingIndex.includes(this.trimmingVideoIndex);
    },
  },
  filters: {
    parseVideoURL(file) {
      return URL.createObjectURL(file);
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeOverlay');
    },
    displayVideoDurations() {
      const { listItems } = this.$refs;
      if (typeof listItems !== 'undefined') {
        for (let i = 0; i < listItems.length; i += 1) {
          const listItem = listItems[i];
          const videoElement = this.$refs.unTrimmedVideos[i];
          const lengthDescriptorElement = listItem.querySelector(
            '.video-overlay__video-length-description',
          );
          videoElement.addEventListener('loadedmetadata', () => {
            lengthDescriptorElement.innerHTML = secToDigitalStr(videoElement.duration, { showMilliSeconds: false }); // eslint-disable-line
          });
        }
      }
    },
    hasTrimmedRecord(index) {
      return this.trimmedRecords[index] !== undefined;
    },
    // hasUploadingRecord(index) {
    //   return this.uploadingVideos[index] !== undefined;
    // },
    // hasUploadedRecord(index) {
    //   return this.uploadedVideos[index] !== undefined;
    // },
    isTrimmingVideo(index) {
      return index === this.trimmingVideoIndex;
    },
    findVideoIndex(arr, video) {
      return arr.findIndex(v => isEqualFile(v, video));
    },
    isVideoUploading(video) {
      return this.findVideoIndex(this.uploadingVideos, video) > -1;
    },
    isVideoUploaded(video) {
      // console.debug('isVideoUploaded', video);
      return this.findVideoIndex(this.uploadedVideos, video) > -1;
    },
    restoreTrimmedRecords(index) {
      const recordedTrimBoxObj = this.trimmedRecords[index];
      if (recordedTrimBoxObj) this.trimBoxObj = recordedTrimBoxObj;
      // console.debug('restoreTrimmedRecords');
    },
    recordTrimBoxObj(index) {
      this.trimmedRecords[index] = this.trimBoxObj;
    },
    truncateTrimmedRecords() {
      this.trimmedRecords = {};
    },
    initTrimBox() {
      this.trimBoxObj = {
        startTime: 0,
        endTime: this.sliderBarWidth,
        width: this.sliderBarWidth,
      };
    },
    isInTrimBox(trimBoxObj, position) {
      return position > trimBoxObj.startTime && position < trimBoxObj.endTime;
    },
    makeFileUrl(file) {
      return URL.createObjectURL(file);
    },
    parsePositionToTime(position) {
      return position / this.sliderBarWidthForAnInterval / 10;
    },
    playVideo() {
      try {
        this.elTrimmingVideo[this.pauseOrPlay]();
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
          this.startVideoCurrentTimeIndicator();
        } else if (this.videoInterval) {
          clearInterval(this.videoInterval);
        }
      } catch (e) {
        // TODO: throw err
      }
    },
    isLastVideo(arr, index) {
      return arr.length === index + 1;
    },
    removeVideo(videoIndex) {
      // there's no remove video function if there's only one video
      const isSolo = this.videos.length === 1;
      if (!isSolo) {
        const isLast = this.isLastVideo(this.videos, videoIndex);
        let newVideoIndexToTrim = null;
        if (this.trimmingVideoIndex === videoIndex) {
          newVideoIndexToTrim = isLast ? videoIndex - 1 : videoIndex;
        }
        // console.debug('removeVideo', {
        //   videoIndex,
        //   newVideoIndexToTrim,
        // });
        this.removeTrimmedRecord(videoIndex);
        this.reorderTrimmedRecords(videoIndex);
        // this.removeUploadedVideo(videoIndex);
        // this.reorderUploadedVideo(videoIndex);
        this.$emit('remove', videoIndex);
        if (newVideoIndexToTrim !== null) {
          this.$nextTick(() => {
            if (newVideoIndexToTrim === videoIndex) {
              this.resetTrimmingVideo();
            } else {
              this.trimVideo(newVideoIndexToTrim);
            }
          });
        } else {
          this.trimVideo(0); // get first video index
        }
      }
    },
    removeTrimmedRecord(index) {
      if (this.hasTrimmedRecord(index)) delete this.trimmedRecords[index];
      // console.debug('removeTrimmedRecord', index);
    },
    // removeUploadingVideo(videoIndex) {
    //   const index = this.uploadingVideos.indexOf(videoIndex);
    //   if (index > -1) this.uploadingVideos.splice(index, 1);
    // },
    // removeUploadedVideo(videoIndex) {
    //   const index = this.uploadedVideos.indexOf(videoIndex);
    //   if (index > -1) this.uploadedVideos.splice(index, 1);
    // },
    reorderTrimmedRecords(startFrom) {
      const initIndex = startFrom + 1;
      const lastIndex = this.videos.length;
      for (let i = initIndex; i < lastIndex; i += 1) {
        if (this.hasTrimmedRecord(i)) {
          const newIndex = i - 1;
          this.trimmedRecords[newIndex] = this.trimmedRecords[i];
          delete this.trimmedRecords[i];
        }
      }
    },
    // reorderUploadingVideo(startFrom) {
    //   const initIndex = startFrom + 1;
    //   const lastIndex = this.videos.length;
    //   for (let i = initIndex; i < lastIndex; i += 1) {
    //     if (this.hasUploadingRecord(i)) {
    //       const newIndex = i - 1;
    //       this.uploadingVideos[newIndex] = this.uploadingVideos[i];
    //       delete this.uploadingVideos[i];
    //     }
    //   }
    // },
    // reorderUploadedVideo(startFrom) {
    //   const initIndex = startFrom + 1;
    //   const lastIndex = this.videos.length;
    //   for (let i = initIndex; i < lastIndex; i += 1) {
    //     if (this.hasUploadedRecord(i)) {
    //       const newIndex = i - 1;
    //       this.uploadedVideos[newIndex] = this.uploadedVideos[i];
    //       delete this.uploadedVideos[i];
    //     }
    //   }
    // },
    resetFullVideoLength(val) {
      this.fullVideoLength = val;
    },
    resetTrimmingVideo() {
      const video = this.videos[this.trimmingVideoIndex];
      this.$set(this, 'trimmingVideo', video);
      // console.log('resetTrimmingVideo', video);
    },
    setTrimmingVideo(video) {
      this.$set(this, 'trimmingVideo', video);
      // console.log('setTrimmingVideo', video);
    },
    setTrimmingVideoIndex(index) {
      this.$set(this, 'trimmingVideoIndex', index);
      this.loadingVideoFailed = false;
      // console.debug('setTrimmingVideoIndex', index);
    },
    showFileBrowser() {
      this.$refs.fileUpload.click();
    },
    startVideoCurrentTimeIndicator() {
      const { trimBoxObj } = this;
      this.videoInterval = setInterval(() => {
        // one sec as width on slider bar
        let position = this.videoCurrentTimeIndicatorPosition;
        // add one interval(sec)
        position += this.sliderBarWidthForAnInterval;
        // maxPosition to reset video if it reaches the end
        const maxPosition = trimBoxObj.endTime;

        // this condition can make sure that the video won't play beyond endTime
        if (position < maxPosition) {
          // check that slider bar is in trim box
          if (!this.isInTrimBox(trimBoxObj, position)) {
            // console.log('Video play time exceeding trim box');
            // user defined trim point won't be fit with the sliderBarWidthForAnInterval,
            // so we need to adjust it.

            // calc the spike length
            const spike = trimBoxObj.startTime % this.sliderBarWidthForAnInterval;
            // remove spike and sum correct interval length
            position = trimBoxObj.startTime - spike + this.sliderBarWidthForAnInterval;
          }
          // console.log('set video indicator position');
          this.videoCurrentTimeIndicatorPosition = position;
        } else {
          // reset video
          clearInterval(this.videoInterval);
          this.elTrimmingVideo.pause();
          this.isPlaying = false;
          this.updateVideoCurrentTimeIndicatorPosition(trimBoxObj.startTime);
        }
      }, 100);
    },
    trimBoxUpdating({ width, left }) {
      this.trimBoxObj.width = width;
      this.trimBoxObj.startTime = left;
      this.trimBoxObj.endTime = left + width;
    },
    trimBoxUpdated({ width, left }) {
      this.trimBoxObj.width = width;
      this.trimBoxObj.startTime = left;
      this.trimBoxObj.endTime = left + width;

      // to update video current time
      this.videoCurrentTimeIndicatorPosition = left;
      this.elTrimmingVideo.currentTime = this.parsePositionToTime(left);
    },
    reset() {
      // console.debug('reset');
      this.truncateTrimmedRecords();
      this.setTrimmingVideoIndex(null);
    },
    resetVideoTrimmerControls() {
      this.videoCurrentTimeIndicatorPosition = this.trimBoxObj.startTime;
      if (this.videoInterval) clearInterval(this.videoInterval);
      this.isPlaying = false;
    },
    trimVideo(index) {
      // console.debug('trimVideo', index);
      if (this.trimmingVideoIndex !== null) this.recordTrimBoxObj(this.trimmingVideoIndex);
      this.isTrimmable = true;
      this.setTrimmingVideoIndex(index);
    },
    updateElTrimmingVideo(callback) {
      const elTrimmingVideo = this.$refs.trimmingVideo;

      elTrimmingVideo.onloadedmetadata = () => {
        this.elTrimmingVideo = elTrimmingVideo;
        callback();
      };
      setTimeout(() => {
        // eslint-disable-next-line
        this.loadingVideoFailed = isNaN(elTrimmingVideo.duration);
      }, 200);
    },
    updateSliderHeight() {
      const elSlider = this.$refs.slider;
      if (elSlider) {
        this.sliderHeight = elSlider.offsetHeight;
      }
    },
    updateSliderBarWidth() {
      const elSliderBar = this.$refs.sliderBar;
      if (elSliderBar) {
        this.sliderBarWidth = elSliderBar.offsetWidth;
      }
    },
    updateSliderBarWidthForAnInterval() {
      this.sliderBarWidthForAnInterval = this.sliderBarWidth / this.duration / 10;
    },
    updateTrimBoxMinMaxSizes() {
      const elSlider = this.$refs.slider;

      if (!elSlider) return;

      const sliderWidth = elSlider.clientWidth;
      const sliderHeight = elSlider.clientHeight;

      this.trimBoxMaxSize = {
        width: sliderWidth,
        height: sliderHeight,
      };
      this.trimBoxMinSize = {
        width: this.sliderBarWidthForAnInterval * 15,
        height: sliderHeight,
      };
    },
    updateTrimBoxOuterBound() {
      const { sliderBarWidth } = this;
      this.trimBoxOuterBound.w = sliderBarWidth;
      this.trimBoxOuterBound.x = sliderBarWidth / 2;
    },
    updateTrimBoxKey() {
      this.trimBoxKey = null;
      this.trimBoxKey = this.trimmingVideoIndex;
    },
    draggingVideoCurrentTimeIndicator({ left }) {
      this.updateVideoCurrentTimeIndicatorPosition(left);
    },
    updateVideoCurrentTimeIndicatorPosition(position) {
      this.videoCurrentTimeIndicatorPosition = position;
      this.elTrimmingVideo.currentTime = this.parsePositionToTime(position);
      // console.log('updateVideoCurrentTimeIndicatorPosition', position);
    },
    upload() {
      // TODO: "fail to upload" process

      let uploadEmitdata;
      if (this.loadingVideoFailed) {
        // issue happens when the video cannot load in browser due to codec compatibility
        // upload the video file to process and convert the video and after processing, return to user to trim the video file
        uploadEmitdata = 'trim_after_upload';
      } else {
        uploadEmitdata = [
          {
            start: this.parsePositionToTime(this.trimBoxObj.startTime),
            end: this.parsePositionToTime(this.trimBoxObj.endTime),
          },
        ];
      }

      this.$emit('upload', this.trimmingVideo, uploadEmitdata);
      if (!this.isMultipleVideos) {
        this.closeModal();
      } else {
        // Go to next untrimmed video

        // Check from current index to end
        let untrimmedIndex = this.checkForUntrimmedVideos(
          this.trimmingVideoIndex,
          this.videos.length,
        );
        if (untrimmedIndex >= 0) {
          this.trimVideo(untrimmedIndex);
        } else {
          untrimmedIndex = this.checkForUntrimmedVideos(0, this.trimmingVideoIndex);
          if (untrimmedIndex >= 0) {
            this.trimVideo(untrimmedIndex);
          } else {
            this.closeModal();
          }
        }
      }
    },
    checkForUntrimmedVideos(start, end) {
      for (let i = start; i < end; i += 1) {
        if (!this.isVideoUploaded(this.videos[i]) && !this.isVideoUploading(this.videos[i])) {
          return i;
        }
      }

      return -1;
    },
    videoTrimmerWrapperClass(videoIndex) {
      const cssClass = [];

      if (this.isTrimmingVideo(videoIndex)) {
        cssClass.push('is-trimming');

        if (this.isShowErrorVideoSign) cssClass.push('video-wrapper--failed'); // so when showing 1 of error message the selected video will also have error sign
      }

      return cssClass.join(' ');
    },
  },
  mounted() {
    this.trimmingVideoIndex = 0;
    this.displayVideoDurations();
    // console.log(this.videos, 'MOUNTED');
  },
  updated() {
    if (this.videos.length) this.displayVideoDurations();
  },
  watch: {
    trimmingVideoIndex: {
      handler(index) {
        const trimmingVideo = index !== null ? this.videos[index] : null;
        // console.log('watcher:trimmingVideoIndex', index, trimmingVideo, this.videos);
        this.setTrimmingVideo(trimmingVideo);
      },
      immediate: true,
    },
    trimmingVideo: {
      handler(video) {
        if (video) {
          // console.log('watcher:trimmingVideo', video);
          const index = this.trimmingVideoIndex;

          this.resetFullVideoLength();
          this.$nextTick(() => {
            this.updateElTrimmingVideo(() => {
              this.updateSliderHeight();
              this.updateSliderBarWidth();
              this.updateTrimBoxOuterBound();
              if (this.hasTrimmedRecord(index)) {
                // console.debug('watcher:trimmingVideo:restoreTrimmedRecords');
                this.restoreTrimmedRecords(index);
              } else {
                // console.debug('watcher:trimmingVideo:initTrimBox');
                this.initTrimBox();
              }
              this.updateSliderBarWidthForAnInterval();
              this.updateTrimBoxMinMaxSizes();
              this.resetVideoTrimmerControls();
              this.updateTrimBoxKey();
            });
          });
        }
      },
    },
    videos: {
      handler(videos) {
        switch (true) {
          case !videos.length:
            // if there's no more video to trim, reset all values.
            this.reset();
            this.closeModal();
            break;
          default:
            // no default action
            break;
        }
      },
    },
    loadingVideoFailed(isFail) {
      if (isFail && !this.isMultipleVideos) {
        this.upload(); // if its a single video upload and cannot load preview, proceed to direct upload and close the modal
      } else if (isFail && this.isMultipleVideos) {
        this.failedVideoLoadingIndex.push(this.trimmingVideoIndex); // mark the failed video load index
      }
    },
  },
};
</script>

<style lang="scss">
.video-trimmer__modal {
  z-index: 99;

  .canvas-modal {
    &__main {
      .content {
        padding: unset;
        background: var(--white-darkgrey700);
      }
    }

    &__content {
      height: 100%;
      max-height: 760px;

      @include smallest {
        max-height: 90vh;
      }
    }

    &__closebtn {
      right: 40px;
      color: var(--darkgrey-white) !important;
    }
  }

  .video-trimmer__content-wrapper {
    padding: 50px 100px;
    height: 100%;
  }

  //- --------
  //- --------

  .video-trimmer__header {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    color: var(--darkgrey-white);

    .header__title {
      display: flex;
      align-items: center;
      font-size: 1.5rem;

      .title {
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;
      }

      i {
        font-size: 1.75rem;
        margin-right: 8px;
      }
    }

    .btn-apply {
      width: 95px;
      height: 40px;
      font-size: 0.8125rem;
      background: $blue2;

      &:hover {
        background: $light;
        color: $blue2;
      }

      &.is-disabled {
        pointer-events: none;
        background: $disabledGrey;

        &.is-dark {
          background: $darkGrey600;
          color: $darkGrey200;
        }
      }
    }
  }

  //- --------
  //- --------

  .video-trimmer__container {
    height: calc(100% - 40px);
    padding: 20px 0 0;
    display: flex;

    .video-trimmer__list {
      width: 170px;
      height: 100%;
      position: relative;
      background: var(--lightgrey400-darkgrey7);
      border-radius: 4px;
      padding: 15px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list-title {
        text-align: left;
        font-weight: 500;
        font-size: 0.875rem;
        margin: 0 0 10px;
        color: var(--darkgrey-white);
      }

      &-item {
        width: 100%;
        margin-top: 8px;
      }

      .video-trimmer__video {
        $videoBorderRadius: 4px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;

        &-wrapper {
          position: relative;
          width: 100%;
          height: 90px;
          cursor: pointer;
          display: inline-flex;
          border-radius: $videoBorderRadius;
          box-shadow: 0 1px 1px rgba(10, 31, 68, 0.12), 0 0 1px rgba(10, 31, 68, 0.1);
          overflow: hidden;

          &.is-trimming {
            border: 3px solid var(--blue700-green500);
          }

          &.video-wrapper--failed {
            .failed-video-thumbnail {
              display: flex;
            }
          }
        }

        &-overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.6) 4.93%, rgba(0, 0, 0, 0) 97.89%);

          &:hover {
            .btn--delete {
              display: block;
            }
          }

          &.is-uploaded {
            .icon-tick {
              display: block;
            }
          }

          &.is-uploading {
            .label-uploading,
            .running-dots {
              display: block !important;
            }

            .video-overlay__video-length-description {
              display: none !important;
            }
          }

          &.is-uploaded,
          &.is-uploading {
            background: #0c66ff90 !important;

            .btn--delete,
            .failed-video-thumbnail {
              display: none !important;
            }
          }

          .btn--delete,
          .icon-tick,
          .label-uploading,
          .running-dots,
          .video-overlay__video-length-description {
            display: none;
            font-size: 24px;
            position: absolute;
          }

          .failed-video-thumbnail {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: var(--white-darkgrey700);
            align-items: center;
            justify-content: center;
            cursor: default;
            z-index: 2;

            .thumbnail-icon {
              font-size: 1.5rem;
              color: var(--darkgrey-white);
            }
          }

          .btn--delete {
            bottom: 8px;
            left: 8px;
            font-size: 0.8125rem;
            color: $light;
            border-radius: 4px;
          }

          .icon-tick {
            color: $lightGreen;
            top: 8px;
            left: 8px;
          }

          .video-overlay__video-length-description {
            display: block;
            font-size: 0.8125rem;
            font-weight: 400;
            color: $lightGrey100;
            bottom: 8px;
            right: 8px;
          }

          .label-uploading {
            font-weight: 400;
            font-size: 0.875rem;
            color: $lightGrey100;
            bottom: 8px;
            left: 8px;
            right: unset;
          }

          .running-dots {
            bottom: 12px;
            right: 8px;
            box-sizing: border-box;
            margin: auto;
            font-size: 0;
            color: #fff;
          }

          .running-dots > div {
            box-sizing: border-box;
            display: inline-block;
            float: none;
            background-color: currentColor;
            border: 0 solid currentColor;
          }

          .running-dots {
            width: 6px;
            height: 6px;
          }

          .running-dots > div {
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            margin-left: 12px;
            animation: ball-running-dots-animate 2s linear infinite;
          }

          .running-dots > div:nth-child(1) {
            animation-delay: 0s;
            left: -12px;
          }

          .running-dots > div:nth-child(2) {
            animation-delay: -0.5s;
            left: calc(-12px * 2);
          }

          .running-dots > div:nth-child(3) {
            animation-delay: -1s;
            left: calc(-12px * 3);
          }

          .running-dots > div:nth-child(4) {
            animation-delay: -1.5s;
            left: calc(-12px * 4);
          }

          /*
          * Animation
          */
          @keyframes ball-running-dots-animate {
            0%,
            40%,
            100% {
              width: 100%;
              height: 100%;
              transform: translateX(0) translateY(0);
            }

            20% {
              width: 150%;
              height: 150%;
              transform: translateX(-25%) translateY(-25%);
            }
          }
        }
      }

      .upload-another-button__wrapper {
        margin-top: 8px;

        input[type='file'] {
          display: none;
        }

        .upload-another-button {
          background: var(--lightgrey600-darkblue6);
          width: 100%;
          height: 90px;
          padding: 0;
          font-size: 1.5rem;
          border-radius: 4px;
          color: $light;
          transition: background 0.15s ease-in-out;

          &:hover {
            background: darken($lightGrey600, 3);
          }

          &.is-dark {
            &:hover {
              background: darken($darkBlue6, 3);
            }
          }
        }
      }
    }

    .video-trimmer__preview {
      width: calc(100% - 170px);
      height: 100%;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      video {
        width: 100%;
        max-height: 85%;
      }

      .failed-video-loading__container {
        background: var(--lightgrey400-darkgrey5);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        .container-icon {
          font-size: 3rem;
          color: var(--blue700-green500);
        }

        .container-title {
          margin: 40px 0 20px;
          font-size: 1.25rem;
          font-weight: 600;
          color: var(--darkgrey-white);
        }

        .container-description {
          color: var(--darkgrey-white);
          font-size: 0.875rem;
          line-height: 1.6;
          font-weight: 400;
          margin: 0;

          &.description--bottom {
            margin-top: 20px;
          }

          strong {
            margin: 0 4px;
          }
        }

        &.container--try-again {
          .container-title {
            margin: 20px 0 0;
          }

          .container-description {
            margin: 10px 0 15px;
          }

          .container-button {
            background: $blue2;
            width: 110px;

            &:hover {
              background: $light;
              color: $blue2;
            }
          }
        }
      }

      .video-uploaded-text {
        margin: 0 auto;
        color: var(--darkgrey-white);
      }
    }
  }

  //- --------
  //- --------

  .video-trimmer__controls {
    height: 15%;
    display: inline-flex;
    align-items: flex-end;
    width: 100%;
    justify-content: flex-start;

    &.controls--vertical-align-center {
      align-items: center;
    }

    &__button {
      display: inline-block;
      margin-right: 10px;

      &.btn {
        &--play {
          position: relative;
          color: $blue;
          font-size: 2.875rem;
          line-height: 0.5;
          padding: 0;
          background: transparent;
          width: 50px;
          box-shadow: none;

          &:hover {
            color: $blue600;
          }

          .icon {
            z-index: 3;
            position: relative;
          }

          // to add white background in play/pause button with smaller size than it's icon
          .white-background-overlay {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 19px;
            height: 21px;
            background: $light;
            border-radius: 50%;
          }
        }
      }
    }

    &__slider {
      width: 100%;
      height: 44px;
      position: relative;

      &:hover {
        .start-time-sign,
        .end-time-sign {
          opacity: 1 !important;
          visibility: visible !important;
        }
      }

      .slider-bar {
        height: 2px;
        width: 100%;
        background: var(--lightgrey400-darkgrey600);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .slider__active-bar {
        height: 2px;
        background: var(--darkgrey-lightgreen2);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .slider-bar__indicator {
        background: var(--darkgrey-white);
        cursor: pointer;
        transition: transform 0.1s linear;
        z-index: 5 !important;

        &::before {
          outline: 0 !important;
        }
      }
    }
  }
}
</style>
